.tv-login-layout {
    &__container {
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      .tv-logo {
        margin: 15px 24px;
      }
    }
    &__form-container {
      display: flex;
      margin: auto;
      flex-direction: column;
      justify-content: center;
    }
  }
  