.tv-tab_button {
  background: #fff;
  border-width: 0px;
  height: 48px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  color: #999999;
  padding: 14px 12px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: rgba(104, 121, 223, 0.1);
  }
  &--disabled {
    color: #252525 !important;
  }
}
