.tk-calendar {
  &__wrapper {
    background: $WHITE_COLOR;
    border-radius: 5px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
  }
  &__header {
    text-align: center;
  }
  &__title {
    display: inline-block;
    color: $PRIMARY_COLOR;	
    font: 1rem/1.5rem  $SECONDARY_FONT;
    font-weight: bold;
    margin: 13px 0;
    .icon-calendar {
      &:before {
        margin-right: 8px;
      }
    }
  }
  
  &__button {
    @extend .tv-buttons__normal;
    padding: 0;    
    outline: none;
    cursor: pointer;
    min-height: 30px;

    &:before {      
      @include tv-transvoice-font;
      color: #bdbdbd;      
      cursor: pointer;
    }
    
    &--previous {
      &:before {
        content: "\e932";
        margin:0 30px;
      }
    }
    &--next {
      &:before {
        content: "\e931";
        margin:0 30px;
      }
    }
  }
  &__main {
  }

  &__tabs{
    &-wrapper {
      margin: 0;
      padding: 0;
      display: inline-flex;
      position: absolute;
      right: 15px;
      top: 10px;
      > li{
        list-style: none;
      }
    }
    &-button{
      border: 0;
      background: transparent;
      color: $TEXT_INACTIVE_COLOR;	
      font: 0.875rem/1.125rem $SECONDARY_FONT;
      font-weight: bold;	
      outline: none;
      cursor: pointer;
      padding: 0 0 0 9px;
      &.selected{
        pointer-events: none;
        cursor: default;
        color: $PRIMARY_COLOR;
      }
    }
  }
}


.tk-dialog { 
  &__wrapper {
    position: absolute;
    min-height: 150px;	
    width: 387px;
    left: calc(100% + 10px);
    top: -2px;
    box-shadow: 0 0 18px 0 rgba(197,197,197,0.65);
    border-radius: 5px;
    z-index: 2;
    padding: 23px 35px;
    background: $WHITE_COLOR;
    cursor: default;

    &--before {
      right: 100%;
      top: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;      
      border-right-color: $WHITE_COLOR;
      border-width: 5px;      
    }
  }

  &__btn-close{
    top: 8px;
    right: 10px;
    position: absolute;
    &:before {
      content: "\e926";
      color: $BLACK_COLOR;
      font-size: 0.6rem;
    }
  }
  &__body{

  }
}