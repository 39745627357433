.tv-dashboard {
  &-grid-template {
    width: 100%;
    flex-direction: column;
    @supports (display: grid) {
      display: grid !important ;
      gap: 15px;
      grid-template-areas:
        "basicCard basicCard basicCard"
        "pieCard0 pieCard0 pieCard1"
        "tableCard0 tableCard0 pieCard1"
        "tableCard0 tableCard0 ."
        "tableCard1 tableCard1 .";
      @media (min-width: 1900px) {
        gap: 20px;

        grid-template-areas:
          "basicCard basicCard "
          "pieCard0  pieCard1"
          "tableCard0 tableCard1 "
          "tableCard0 tableCard1 ";
      }
      @media only screen and (max-width: 768px) {
        gap: 20px;
        width: calc(100vw - 35px) !important;
        grid-template-areas:
          "basicCard  "
          "basicCard  "
          "basicCard  "
          "pieCard0  "
          "pieCard1  "
          "tableCard0  "
          "tableCard1  ";
      }
    }
    display: flex;
  }
}
