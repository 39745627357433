@import "../../../../Share/styles/variables.scss";

.content {
  display: grid;
  border-radius: 8px;

  grid-area: content;
  min-height: calc(400px - 48px);
}
.loaderContent {
  display: grid;
  border-radius: 8px;

  grid-area: content;
  min-height: calc(400px - 48px);
  align-items: center;
  justify-content: center;
}
