.kpi-heading {
  &--primary {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #74737b;
  }
  &--secondary {
    font-weight: 400;
    font-size: 14px;

    color: #74737b;
  }
  &--text{
    font-weight: 400;
    font-size: 13px;
color: #252525 !important;
  }
  &--big-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #252525;
  }
}
