@import "../../../../Share/styles/variables.scss";

.kpi-home-sidenav {
  &__container {
    border: $COMMON_BORDER;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 8px;
    width: clamp(320px, 23vw, 400px);
  }
  @media only screen and (max-width: 768px) {
    &__container {
      width: calc(100vw - 35px);
    }
  }
}
