// color
$PRIMARY_COLOR: #20175b;
$PRIMARY_HOVER_COLOR: #474b90;
$SECONDARY_COLOR: #322878;
$PURPLE_COLOR: #231950;
$TEXT_COLOR: #323232;
$TEXT_INACTIVE_COLOR: #c5c5c5;
$PURPLE_BLOOD_COLOR: #e1e6ff;
$BACKGROUND_COLOR: #f7f9ff;
$NAV_BAR_HOVER_COLOR: #fafafa;
$NAV_BAR_TEXT_COLOR: #313131;
$WHITE_COLOR: #fff;
$BLACK_COLOR: #000;
$TITLE_COLOR: #f9f9f9;
$TEXT_TITLE_COLOR: #353535;
$LIGHT_PINK_COLOR: #ffd2cd;
$PINK_COLOR: #ff9ba0;
$PURPLE_BLUE_COLOR: #6478e6;

$DARK_GRAY_COLOR: #a9a9a9;
$DARK_GRAY_COLOR-1: #f7f7f7;
$BORDER_COLOR: #f1f1f1;
$BGCOLOR-ICON: #d1d1d1;
// fonts
$PRIMARY_FONT: "Circular Std", sans-serif;
$SECONDARY_FONT: "Circular Std", serif;

$SCROLLBAR_WIDTH: 10px;
$LIGHT_GRAY_COLOR: #d8d8d8;

// Status Color
$COLOR_STATUS_AVAILABLE: #ff0000;
$COLOR_STATUS_ACCEPTED: #0000ff;
$COLOR_STATUS_REJECTED: #343a40;
$COLOR_STATUS_FULLILLED: #fec00d;
$COLOR_STATUS_SUBMITTED: #008000;
$COLOR_STATUS_WORKING: #fbbcdb;
$COLOR_STATUS_DONE: #ffa500;
$COLOR_STATUS_CANCELLED: #000000;
$COLOR_STATUS_LOSTED: #696969;
$COLOR_STATUS_CUSTOM: #9c69ad;

$breakpoint-tablet: 768px;

$COMMON_BORDER: 1px solid #e0e0e0;
$INACTIVE_LINK_COLOR: #999999;
$WHITE_COLOR: #fff;
$DARK_GRAY_COLOR: #a9a9a9;

$COMMON_TEXT_COLOR: #252525;

$NAVIGATION_COLOR: #999999;
$NAVIGATION_HOVER_COLOR: #20175b;
$COMMON_TEXT_COLOR: #252525;

$CARD_SHADOW: 0px 1px 5px rgba(0, 0, 0, 0.16);
