@import "../../../../Share/styles/variables.scss";

.contact_card_wrapper {
  display: flex;
  flex-direction: column;
  // margin-bottom: 230px;
  width: 100%;
  height: auto;
  .col {
    flex-basis: unset;
  }
}
:root {
  --page-margin: max(25px, min(5%, 25px));
}
.page-title {
  display: flex;
  font: 400 28px /35px $PRIMARY_FONT;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    margin-top: 38px;
    font: 400 24px /30px $PRIMARY_FONT;
    flex-direction: column;
  }
}
.title {
  font: 400 28px/35px $PRIMARY_FONT;
}
.page-footer {
  display: flex;
  left: 0;
  height: 160px;
  bottom: 0;
  justify-content: center;
  background-color: #f8f8f8;
  width: 100%;

  img {
    float: right;
    margin: 0 32px 40px 0;
  }
  @media screen and (max-width: 768px) {
    height: 94px;
    justify-content: center;
    position: fixed;
    img {
      float: unset !important;
      margin: 0 auto 32px;
    }
  }
}

.generic_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
  min-width: 300px;
  width: 100%;
  min-height: 320px;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    height: 150px;
    border-radius: 4px;
    background: #fff;
    width: auto;
  }
}
.contact_image {
  min-height: 276px;
  @media screen and (max-width: 768px) {
    min-height: 180px;
    img {
      height: 100% !important;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}
.contact_city {
  padding-top: 15px;
  margin-bottom: 8px;
  margin-top: 16px;
  font: 24px/30px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    font: 24px/30px $PRIMARY_FONT;
  }
}

.contact_address_wrapper {
  font: 16px/20px $PRIMARY_FONT;
  @media screen and (max-width: 768px) {
    font: 16px/20px $PRIMARY_FONT;
  }
}
.contact_method_wrapper {
  display: flex;
  padding-top: 15px;
  display: inline-flex;
  padding-bottom: 15px;
  font: 16px/20px $PRIMARY_FONT;

  &-method {
    display: flex;
    flex-direction: column;
    line-height: 130%;
  }

  &-method a {
    color: $PRIMARY_COLOR;
    margin-right: 5px;
    ::before {
      content: ":";
      color: $COMMON_TEXT_COLOR;
    }
  }
  @media screen and (max-width: 768px) {
    font: 16px/20px $PRIMARY_FONT;
  }
}
.faq_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 0 85px 0;
  @media screen and (max-width: 768px) {
    margin: 0px 0 0px 0;
  }
}

.drop_accordian {
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  border: 1px solid $BORDER_COLOR;
  font: 16px/20px "Circular Std", sans-serif;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.question_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font: 500 19px/24px "Circular Std", sans-serif;
  padding: 0px 22px 0px 24px;
  :hover {
    cursor: pointer;
  }
}
.heading {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 500;
  justify-content: space-between;
  padding: 24px 0 24px 0;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.icon_wrapper {
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
.icon {
  font-size: 16px;
}

.body {
  display: flex;
  font: 16px/20px "Circular Std", sans-serif;
  flex-direction: column;
  p {
    margin-bottom: 0;
    margin-top: 16px;
    font-weight: 100;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}

.page_content {
  color: $COMMON_TEXT_COLOR;
  background-color: $BACKGROUND_COLOR;
  display: flex;
  flex-direction: column;
  margin: 0 var(--page-margin);
  padding-bottom: 110px;
  width: 100%;
  @supports not (margin: var(--page-margin)) {
    margin: 0 16px;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    height: fit-content;
    overflow: scroll;
  }
  overflow: hidden;
}
.contact_now_button {
  font-family: $PRIMARY_FONT;
  background: $PRIMARY_COLOR;
  border: none;
  outline: none;
  color: white;
  padding: 14px;
  width: 30%;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.contact_now_button_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
