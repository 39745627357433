html,
body {
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "PT Serif";
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

* {
  outline: none;
}

a {
  text-decoration: none;

  &:active,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}
.bn_topbar a:hover {
  text-decoration: none;
}
input {
  /*-webkit-appearance:none;*/
  box-sizing: border-box;
}
